import * as React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import {navigate} from 'gatsby'; //import navigate from gatsby

const Home = () =>{
    navigate('/')
}
const ThankYou = () => (
  <Layout>
    <SEO title="Thank you" />
    <div className="section-100vh center " style={{backgroundColor:"rgb(172, 171, 154)"}}>
      <div className="flex-colum">
       
          <h1 className="text-center George large white" >
            Thank you for submitting
          </h1>
          <div className="justify"> 
          <button  onClick={() =>{Home()}} className="submit" style={{backgroundColor:"rgb(172, 171, 154)"}}>
            <h5 className="text white">Home</h5>
          </button>
          </div>
         
      </div>
    </div>
  </Layout>
)

export default ThankYou
